import ListAPI from "@/apps/core/api/list";
import { Shu, ShuAPI } from "./shu";
import ViewListModel from "@/apps/core/models/viewListModel";

class ShuListAPI extends ListAPI {
  constructor() {
    super(ShuAPI.modelPath);
  }
}

class ShuListVM extends ViewListModel {
  constructor() {
    super(new ShuListAPI(), undefined, false);
  }

  getInstance(json: Record<string, any>): Shu {
    const potongan = new Shu();
    potongan.loadData(json);
    return potongan;
  }
}

export { ShuListAPI };
export default ShuListVM;
