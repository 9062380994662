import APP_CONFIG from "@/apps/core/modules/config";

const truncate = (text: string, limit = APP_CONFIG.textLimit): string => {
  if (text.length > limit) {
    return text.substring(0, limit) + "…";
  }
  return text;
};

const isEmail = (email: string): boolean => {
  const emailRegExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (emailRegExp.test(email)) {
    return true;
  }
  return false;
};

const formatRupiah = (value: number, decimalPlaces = 0): string => {
  const idLocale = new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(value);
  return idLocale;
};

// const isEmail2 = (email: string) => {
//   return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
// }

// const dashIfEmpty = (text: string) => {
//   return text === "" ? "-" : text;
// }

export {
  // dashIfEmpty,
  formatRupiah,
  isEmail,
  truncate,
};
