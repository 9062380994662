import Excel from "exceljs";
import { ToastProgrammatic as Toast } from "buefy";
import axios from "axios";
import { Shu } from "../models/shu";

const COLS = [
  "NO",
  "NO.PEG",
  "NAMA",
  "UNIT",
  "S.POKOK",
  "S.WAJIB",
  "S.SUKARELA",
  "SP,SW,SS",
  "SISA PINJAMAN",
  "BUNGA YG DIBAYARKAN",
  "JML BULAN SAHAM",
  "JASA SIMPANAN",
  "JASA PINJAMAN",
  "TOTAL JASA",
  "TERIMA SHU"
];


class ShuExcel {
  apiUrl: string;
  onSaved: (() => void) | null;
  onFinished: (() => void) | null;
  year: string;
  filename: string | null = null;
  constructor(
    apiUrl: string,
    year: string,
    onSaved: (() => void) | null,
    onFinished: (() => void) | null
  ) {
    this.apiUrl = apiUrl;
    this.onSaved = onSaved;
    this.onFinished = onFinished;
    this.year = year;
  }

  async writeWorkbook(): Promise<void> {
    axios
      .get(this.apiUrl, {
        params: { "fields_type": "options", tahun: this.year },
      })
      .then((response: Record<string, any>) => {
        this._writeToExcel(response.data.data);
      })
      .catch(() => {
        // this.reset();
        Toast.open("File gagal di download.");
      });
  }

  getCurrentDatetime(): string {
    const currentdt = new Date();
    const mm = currentdt.getMonth() + 1; // getMonth() is zero-based
    const dd = currentdt.getDate();
    const hh = currentdt.getHours();
    const mi = currentdt.getMinutes();
    const se = currentdt.getSeconds();

    return [
      currentdt.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
      (hh > 9 ? "" : "0") + hh,
      (mi > 9 ? "" : "0") + mi,
      (se > 9 ? "" : "0") + se,
    ].join("");
  }

  async _writeToExcel(dataList: Array<Record<string, any>>): Promise<void> {
    let num = 0;
    const jsonArr: Array<any> = [];
    for (const data of dataList) {
      num++;
      const shu = new Shu();
      shu.loadData(data);
      const row = [
        num,
        shu.nasabah?.noAnggota,
        shu.nasabah?.nama,
        shu.nasabah?.unitKerja?.nama ?? "",
        shu.sp,
        shu.sw,
        shu.ss,
        shu.totalSimpanan,
        shu.sisaPinjaman,
        shu.bunga,
        shu.bulanSaham,
        shu.jasaSimpan,
        shu.jasaPinjam,
        shu.totalJasa,
        shu.terimaShu
      ];
      jsonArr.push(row);
    }
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("SHU");

    const cellA2 = worksheet.getCell("A2");
    const cellA3 = worksheet.getCell("A3");
    worksheet.getRow(1).hidden = true;

    cellA2.value = "KOPERASI YAKOBUS";
    cellA2.style = { font: { size: 20, bold: true } };
    worksheet.getRow(2).height = 27;
    const subJudul = `SHU ${this.year}`;
    cellA3.value = subJudul;
    cellA3.style = { font: { size: 16 } };
    worksheet.getRow(3).height = 18;

    worksheet.getRow(5).values = COLS;
    worksheet.getColumn("A").width = 5; // no
    worksheet.getColumn("B").width = 10; // no anggota
    worksheet.getColumn("C").width = 25; // nama
    worksheet.getColumn("D").width = 15; // unit
    for (const i of Array(11).keys()) {
        worksheet.getColumn(i+5).width = 25;
    }

    worksheet.getRow(5).alignment = {
    wrapText: true,
    vertical: "middle",
    horizontal: "center",
    };
    for (const cidx of Array(COLS.length).keys()) {
        worksheet.getCell(5, cidx + 1).fill = {
            type: "pattern",
            pattern: "lightGray",
        };
        worksheet.getCell(5, cidx + 1).font = { bold: true };
        worksheet.getCell(5, cidx + 1).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        };
    }

    const startRowNum = 6;
    for (const rowNum of Array(jsonArr.length).keys()) {
      for (const cidx of Array(COLS.length).keys()) {
        const dataCell = worksheet.getCell(rowNum + startRowNum, cidx + 1);
        const val = jsonArr[rowNum][cidx];
        dataCell.value = val;
        if (cidx > 4) dataCell.numFmt = "#,##0.00";
        worksheet.getCell(rowNum + startRowNum, cidx + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    this.filename = `SHU_${this.year}_${this.getCurrentDatetime()}.xlsx`
      .replace("-", "")
      .split(" ")
      .join("_");
    link.setAttribute("download", this.filename); //or any other extension
    // link.download = this.filename;
    document.body.appendChild(link);
    link.click();
    // window.URL.revokeObjectURL(url);
  }
}

export default ShuExcel;
