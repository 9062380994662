






















































































































































































































import { computed, defineComponent } from "@vue/composition-api";
import useListViewModel from "@/apps/core/modules/useListViewModel";
import { formatRupiah } from "@/apps/core/modules/utils/text";
import ShuListVM from "../models/shuList";
import { SaveContext } from "@/apps/core/models/formModel";
import { DialogProgrammatic as Dialog } from "buefy";
import ShuTahunanFM from "../models/shuTahunanForm";
import useFormModel from "@/apps/core/modules/useFormModel";
import { ShuTahunan } from "../models/shuTahunan";
import APP_CONFIG from "@/apps/core/modules/config";
import ShuExcel from "../modules/shuExcel";

export default defineComponent({
  name: "ShuList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const today = new Date();
    const year = (today.getUTCFullYear() - 1).toString();

    const listVM = new ShuListVM();
    listVM.addFilterMap({ tahun: "" });
    const composition = useListViewModel(listVM);

    const form = new ShuTahunanFM(["tahun", "keuntungan", "persentase"], []);
    const compForm = useFormModel(form);
    const shuTahunan = compForm.instance as ShuTahunan;
    shuTahunan.tahun = today.getUTCFullYear() - 1;
    // shuTahunan.tahun = today.getUTCFullYear();
    form.validate('tahun');

    const generateShu = async () => {
      await form.save(SaveContext.Create, false);
      if (form.getIsValid().value) {
        compForm.formRef.reset();
        shuTahunan.tahun = today.getUTCFullYear() - 1;
        // shuTahunan.tahun = today.getUTCFullYear();
        composition.onPageChange(1);
      }
    };

    const confirm = () => {
      Dialog.confirm({
        message: "Anda yakin akan membuat laporan shu?",
        confirmText: "Ya",
        cancelText: "Tidak",
        onConfirm: () => generateShu(),
      });
    };
    composition.filterMap.tahun = year;
    const canGenerate = computed(() => {
      return (
        composition.listVMRef.list.length == 0 &&
        year === composition.filterMap.tahun &&
        !composition.filterMap.unitKerja &&
        !composition.filterMap.q
      );
    });

    const downloadShu = () => {
      const laporanUrl = `${APP_CONFIG.baseAPIURL}/laporan/shu/`;
      const _year = composition.filterMap.tahun;
      const shuExcel = new ShuExcel(
        laporanUrl,
        _year,
        null,
        () => (composition.listVMRef.isLoading = false)
      );
      shuExcel.writeWorkbook();
    };

    const confirmExport = () => {
      Dialog.confirm({
        message: "Download SHU?",
        confirmText: "Ya",
        cancelText: "Tidak",
        onConfirm: () => downloadShu(),
      });
    };

    return {
      // Computed
      canGenerate,

      // Method
      formatRupiah,
      confirm,
      confirmExport,

      // Composition
      ...composition,
      ...compForm,
    };
  },
});
