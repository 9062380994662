import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";


class ShuTahunan extends PlainModel {
  tanggal: string | null = null;
  tahun: number | null = null;
  keuntungan: number | null = null;
  persentase: number | null = null;
}

class ShuTahunanAPI extends API {
  static modelPath = "/laporan/shu-tahunan/";

  constructor() {
    super(ShuTahunanAPI.modelPath);
  }
}


export { ShuTahunan, ShuTahunanAPI };