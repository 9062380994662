import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import { Nasabah } from "@/apps/nasabah/models/nasabah";
import { ShuTahunan } from "./shuTahunan";


class Shu extends PlainModel {
  shuTahunan: ShuTahunan | null = null;
  nasabah: Nasabah | null = null;
  tahun: number | null = null;
  sp: number | null = null;
  sw: number | null = null;
  ss: number | null = null;
  totalSimpanan: number | null = null;
  sisaPinjaman: number | null = null;
  bunga: number | null = null;
  bulanSaham: number | null = null;
  jasaSimpan: number | null = null;
  jasaPinjam: number | null = null;
  totalJasa: number | null = null;
  terimaShu: number | null = null;
}

class ShuAPI extends API {
  static modelPath = "/laporan/shu/";

  constructor() {
    super(ShuAPI.modelPath);
  }
}


export { Shu, ShuAPI };